<template lang="pug">
div#main-background.px-24.px-24
  vs-dropdown.cursor-pointer(vs-custom-content='' vs-trigger-mouse-over='')
    h2 . . .
    //- .con-img.ml-3
    //-   img.rounded-full.shadow-md.cursor-pointer.block(v-if="activeUserImg.startsWith('http')" key='onlineImg' :src='activeUserImg' alt='user-img' width='40' height='40')
    //-   img.rounded-full.shadow-md.cursor-pointer.block(v-else='' key='localImg' :src='require(`@/assets/images/portrait/small/${activeUserImg}`)' alt='user-img' width='40' height='40')
    vs-dropdown-menu
      ul(style='min-width: 9rem')
        li.flex.py-2.px-4.cursor-pointer(class='hover:bg-primary hover:text-white' @click="$router.push('/apps/chat')")
          feather-icon(icon='MessageSquareIcon' svgclasses='w-4 h-4')
          span.ml-2 Chat
        li.flex.py-2.px-4.cursor-pointer(class='hover:bg-primary hover:text-white' @click="$router.push('/apps/eCommerce/wish-list')")
          feather-icon(icon='HeartIcon' svgclasses='w-4 h-4')
          span.ml-2 Wish List
        li.flex.py-2.px-4.cursor-pointer(class='hover:bg-primary hover:text-white' @click="$router.push('/apps/eCommerce/wish-list')")
          feather-icon(icon='DeleteIcon' svgclasses='w-4 h-4' svgClasses="stroke-current text-danger")
          span.ml-2(style="color:rgb(var(--vs-danger))") Delete
        vs-divider.m-1
        li.flex.py-2.px-4.cursor-pointer(class='hover:bg-primary hover:text-white' )
          feather-icon(icon='LogOutIcon' svgclasses='w-4 h-4')
          span.ml-2 Logout

</template>
<script>
export default {
  props: ["msg"],
  data() {
    return {
      welcome: "This is your profile"
    };
  },
  methods: {
    welcomeMessage() {
      console.log("Hello Method");
    }
  },
  components: {}
};
</script>

<style >
</style>
